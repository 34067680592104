import {
  Box, Chip, Grid,
  ListItem, ListItemSecondaryAction, ListItemText,
  makeStyles
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useDispatch } from "react-redux";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import { appActions } from "../../actions/app.actions";
import { projectsActions } from "../../actions/projects.actions";
import { getUser, isAdmin } from "../../helpers/user";
import MenuActions from "../MenuActions";
import UnarchiveModal from "../UnarchiveModal";

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function ArchivesList(props) {
  const classes = useStyles();
  const [selectedArchive, setSelectedArchive] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseMenuList = (option, project) => {
    if (option === "archive") {
      setSelectedProject(project);
      dispatch(appActions.setCurrentProject(project.id));
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleProjectUnarchived = () => {
    dispatch(projectsActions.edit({ archived: false }));
  }

  const _noRowsRenderer = () => {
    return (
      <div style={{ listStyle: "none" }}>
        <ListItem button style={{ height: "80px" }}>
          <ListItemText
            disableTypography
            primary={
              <Typography style={{ textAlign: "center" }}>
                Aucune archive trouvée
              </Typography>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  };

  const _rowRenderer = ({ index, key, style }) => {
    const archive = props.projects[index];

    return (
      <div key={key} style={{ ...style, listStyle: "none" }}>
        <ListItem
          button
          style={{ height: "80px", listStyle: "none", paddingRight: "75px" }}
        >
          {isAdmin() && 
            <ListItemText
              disableTypography
              primary={
                <Typography variant={"body2"}>
                  {archive.name} <span style={{color: 'rgba(0, 0, 0, 0.54)'}}>- par {archive.user.firstName} {archive.user.lastName}</span>
                </Typography>
              }
              secondary={
                <Box display="flex">
                  <Grid className={classes.textSecondary} container>
                    <Grid item xs={6}>
                      <Typography variant={"body2"}>
                        Client :  {(archive?.finalClient && archive.finalClient.name) || archive.client.name}  {archive?.customRef && ` - Ref : Client :  ${archive?.customRef}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {archive?.won === "Gagné" && 
                        <Chip
                          color="success"
                          variant="outlined"
                          style={{borderColor:'green', color:'green'}}
                          size="small"
                          label="Gagné"
                        />
                      }
                      {archive?.won === "Perdu" && 
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          label="Perdu"
                        />
                      }
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          }

          {!isAdmin() && getUser().decoded.username === archive.user.email && 
            <ListItemText
              disableTypography
              primary={
                <Typography variant={"body2"}>
                  {archive.name} <span style={{color: 'rgba(0, 0, 0, 0.54)'}}>- par {archive.user.firstName} {archive.user.lastName}</span>
                </Typography>
              }
              secondary={
                <Box display="flex">
                  <Grid className={classes.textSecondary} container>
                    <Grid item xs={6}>
                      <Typography variant={"body2"}>
                        Client :  {(archive?.finalClient && archive.finalClient.name) || archive.client.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {archive?.won === "Gagné" && 
                        <Chip
                          color="success"
                          variant="outlined"
                          style={{borderColor:'green', color:'green'}}
                          size="small"
                          label="Gagné"
                        />
                      }
                      {archive?.won === "Perdu" && 
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          label="Perdu"
                        />
                      }
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          }
          
          <ListItemSecondaryAction>
            <MenuActions
                options={{
                  archive: {
                    name: "Désarchiver",
                  },
                }}
                onClose={(option) => handleCloseMenuList(option, archive)}
              />
            </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >

      <div style={{ flex: "1 1 auto", height: "100%" }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <>
                <UnarchiveModal
                  onClose={handleCloseModal}
                  onClick={handleProjectUnarchived}
                  open={openModal}
                  title={"Êtes-vous sûr de vouloir désarchiver ce projet ?"}
                />
                <List
                  overscanRowCount={30}
                  noRowsRenderer={_noRowsRenderer}
                  rowCount={props?.projects?.length || 0}
                  rowHeight={width > 940 ? 105 : 200}
                  rowRenderer={_rowRenderer}
                  width={width}
                  height={height}
                />
              </>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
}
