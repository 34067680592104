import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  useTheme
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import {
  AccountCircle as AccountCircleIcon, Archive as ArchiveIcon, AttachMoney as AttachMoneyIcon,
  ExitToApp as ExitToAppIcon,
  ListAlt as ListAltIcon,
  LocalShipping as LocalShippingIcon,
  Menu as MenuIcon,
  MoneyOff as MoneyOffIcon,
  People as PeopleIcon, SupervisorAccount as SupervisorAccountIcon,
  ViewList as ViewListIcon
} from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { usersActions } from "../actions/users.actions";
import { getUser, isAdmin } from "../helpers/user";
import logo from "../logo.svg";
import AccountEdit from "./account/AccountEdit";
import AccessoriesIndex from "./admin/accessories/Index";
import ArchivesIndex from "./admin/archives/Index";
import ClientsIndex from "./admin/clients/Index";
import InsulationsIndex from "./admin/insulations/Index";
import PricesEstimatedIndex from "./admin/prices-estimated/Index";
import PricesIndex from "./admin/prices/Index";
import ProductsIndex from "./admin/products/Index";
import SettingsIndex from "./admin/settings/Index";
import ShippingIndex from "./admin/shipping/Index";
import UsersIndex from "./admin/users/Index";
import ListContainer from "./projects/Index";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  listItem: {
    "&:hover": {
      color: "red",
    },
    "&:focus": {
      color: "red",
    },
  },
  bar: {
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(true);

  useEffect(() => {
    dispatch(usersActions.fetchUsersIfNeeded());
  }, [dispatch]);

  let users = useSelector((state) => {
    return Object.values(state.users.all).filter(
      (user) => user.managed && user.id !== getUser()?.decoded.userId
    );
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setOpenDropdown(!openDropdown);
  };

  const drawer = (
    <>
      <div className={classes.toolbar} />
      <Divider />
      <List className={classes.list}>
        <List>
          <ListItem
            className={classes.listItem}
            component={NavLink}
            activeClassName="Mui-selected"
            to="/projects"
            button
          >
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText primary="Projets" />
          </ListItem>

          <ListItem
            className={classes.listItem}
            component={NavLink}
            activeClassName="Mui-selected"
            to="/account"
            button
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Compte" />
          </ListItem>

          {isAdmin() && <Divider />}
          {users.length > 0 && (
            <ListItem
              component={NavLink}
              activeClassName="Mui-selected"
              to="/users"
              className={classes.listItem}
              button
            >
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary="Utilisateurs" />
            </ListItem>
          )}

          {isAdmin() && (
            <ListItem
              className={classes.listItem}
              component={NavLink}
              activeClassName="Mui-selected"
              to="/clients"
              button
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItem>
          )}

          {isAdmin() && (
            <ListItem
              className={classes.listItem}
              component={NavLink}
              activeClassName="Mui-selected"
              to="/products"
              button
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Produits" />
            </ListItem>
          )}

          {isAdmin() && (
            <ListItem
              className={classes.listItem}
              component={NavLink}
              activeClassName="Mui-selected"
              to="/insulations"
              button
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Isolations" />
            </ListItem>
          )}

          {isAdmin() && (
            <ListItem
              className={classes.listItem}
              component={NavLink}
              activeClassName="Mui-selected"
              to="/accessories"
              button
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Accessoires" />
            </ListItem>
          )}

          {isAdmin() && (
            <ListItem
              className={classes.listItem}
              component={NavLink}
              activeClassName="Mui-selected"
              to="/prices"
              button
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Tarifs" />
            </ListItem>
          )}

          {isAdmin() && (
            <ListItem
              className={classes.listItem}
              component={NavLink}
              activeClassName="Mui-selected"
              to="/prices-estimated"
              button
            >
              <ListItemIcon>
                <MoneyOffIcon />
              </ListItemIcon>
              <ListItemText primary="Tarifs Estimatifs" />
            </ListItem>
          )}

          {isAdmin() && (
            <ListItem
              className={classes.listItem}
              component={NavLink}
              activeClassName="Mui-selected"
              to="/shipping"
              button
            >
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Transport" />
            </ListItem>
          )}

          <ListItem
            className={classes.listItem}
            component={NavLink}
            activeClassName="Mui-selected"
            to="/archives"
            button
          >
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText primary="Archives" />
          </ListItem>
        </List>

        <List className={classes.bottomList}>
          <ListItem
            className={classes.listItem}
            component={NavLink}
            activeClassName="Mui-selected"
            to="/login"
            button
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Déconnexion" />
          </ListItem>
        </List>
      </List>
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.bar}>
          <Box display="flex">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} className="App-logo" alt="Logo Onduline" />
          </Box>
          <Button color="inherit">{getUser()?.decoded?.username}</Button>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route path="/projects">
              <ListContainer />
            </Route>
            <Route path="/account">
              <AccountEdit currentUser={getUser().decoded} />
            </Route>
            <Route path="/users">
              <UsersIndex />
            </Route>
            <Route path="/clients">
              <ClientsIndex />
            </Route>
            <Route path="/products">
              <ProductsIndex />
            </Route>
            <Route path="/insulations">
              <InsulationsIndex />
            </Route>
            <Route path="/accessories">
              <AccessoriesIndex />
            </Route>
            <Route path="/prices">
              <PricesIndex />
            </Route>
            <Route path="/prices-estimated">
              <PricesEstimatedIndex />
            </Route>
            <Route path="/shipping">
              <ShippingIndex />
            </Route>
            <Route path="/settings">
              <SettingsIndex />
            </Route>
            <Route path="/archives">
              <ArchivesIndex />
            </Route>
            <Route>
              <Redirect to={"/projects"} />
            </Route>
          </Switch>
        </Container>
      </main>
    </div>
  );
}
