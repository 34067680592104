import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import { pricesEstimatedActions } from "../../../actions/priceEstimated.actions";
import {
  EDIT_PRODUCT_ERROR,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_RESPONSE
} from "../../../actions/product.actions";
import HeaderTitle from "../../../components/HeaderTitle";
import PricesEstimatedRow from "../../../components/pricesEstimated/PricesEstimatedRow";
import { selectPricesEstimated } from "../../../helpers/schema";
import ExportCsvButton from "../../settings/ExportCsvButton";
import ImportCsvButton from "../../settings/ImportCsvButton";

const useStyles = makeStyles({
  container: {
    maxHeight: "78vh",
  },
});

export default function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadItems, _setLoadItems] = React.useState(50);

  const loadItemsRef = React.useRef(loadItems);
  const setLoadItems = (data) => {
    loadItemsRef.current = data;
    _setLoadItems(data);
  };

  const isFetching = useSelector((state) => {
    return (
      state.products.isFetching ||
      state.accessories.isFetching ||
      state.pricesEstimated.isFetching
    );
  }, shallowEqual);

  const pricesEstimated = useSelector((state) => {
    return Object.values(selectPricesEstimated(state)) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(pricesEstimatedActions.fetchPricesEstimatedIfNeeded());
    dispatch(appActions.setNextPage("/prices-estimated"));
  }, [dispatch]);

  const handleRefreshClick = (event) => {
    event.preventDefault();
    dispatch(pricesEstimatedActions.invalidatePricesEstimated());
    dispatch(pricesEstimatedActions.fetchPricesEstimatedIfNeeded());
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      "& td": {
        color: "white",
        fontWeight: "bold",
      },
    },
  }))(TableRow);

  /* Group shippings by label */
  Array.prototype.groupBy = function (key) {
    return this.reduce(function (r, a, i) {
      if (!i || r[r.length - 1][0][key] !== a[key]) {
        return r.concat([[a]]);
      }
      r[r.length - 1].push(a);
      return r;
    }, []);
  };

  const priceRows = pricesEstimated.groupBy("label");

  return (
    <Switch>
      <Route exact path="/prices-estimated">
        <TableContainer>
          <TableRow
          >
            <TableCell align={"center"}>
              <ImportCsvButton entity="price_estimateds">Importer</ImportCsvButton>
            </TableCell>
            <TableCell align={"center"}>
              <ExportCsvButton entity="price_estimateds" fileName="Tarifs-estimatifs">Exporter</ExportCsvButton>
            </TableCell>
          </TableRow>
        </TableContainer>
        <HeaderTitle
          mb={3}
          title="Tarifs estimatif"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
        />

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Désignation</TableCell>
                <TableCell align={"center"}>&lt;&nbsp;100m²</TableCell>
                <TableCell align={"center"}>101 - 300m²</TableCell>
                <TableCell align={"center"}>301 - 600m²</TableCell>
                <TableCell align={"center"}>601 - 1000m²</TableCell>
                <TableCell align={"center"}>&gt;&nbsp;1000m²</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell colSpan={6}>Association Produit + Isolant</TableCell>
              </StyledTableRow>
              {Object.entries(priceRows).map(([key, priceRow], index) => (
                <>
                  <PricesEstimatedRow
                    priceRow={priceRow}
                    productVariantName={priceRow?.[0]?.productVariant?.name}
                    insulationName={priceRow?.[0]?.insulation?.name}
                    key={key + priceRow.id}
                    start={EDIT_PRODUCT_REQUEST}
                    resolve={EDIT_PRODUCT_RESPONSE}
                    reject={EDIT_PRODUCT_ERROR}
                  />
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Route>
    </Switch>
  );
}
