import React, { useEffect } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, Box, CircularProgress, Fab } from "@material-ui/core";
import { accessoriesActions } from "../../../actions/accessories.actions";
import AccessoriesList from "../../../components/accessories/AccessoriesList";
import AccessoriesEdit from "../../../containers/admin/accessories/AccessoriesEdit";
import AccessoriesNew from "../../../containers/admin/accessories/AccessoriesNew";
import AccessoriesShow from "../../../containers/admin/accessories/AccessoriesShow";
import HeaderTitle from "../../../components/HeaderTitle";
import SearchList from "../../../components/searchList";
import { filterElement, selectAccessories } from "../../../helpers/schema";

export default function Index(props) {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = React.useState("");

  const isFetching = useSelector((state) => {
    return state.accessories.isFetching;
  }, shallowEqual);

  const accessories = useSelector((state) => {
    return Object.values(selectAccessories(state)) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
  });

  const handleRefreshClick = (event) => {
    event.preventDefault();

    dispatch(accessoriesActions.invalidateAccessories());
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
  };

  const onSearch = (search) => {
    setSearchFilter(search);
  };

  return (
    <Switch>
      <Route exact path="/accessories">
        <HeaderTitle
          mb={3}
          title="Accessoires"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
          bottom={<SearchList onSearch={onSearch} />}
        />
        {isFetching && Object.entries(accessories).length === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <CircularProgress size={100} />
          </Box>
        )}
        <div className={"accessories-container"} style={{ height: "100%" }}>
          {!isFetching && Object.entries(accessories).length === 0 && (
            <h2>Aucune donnée disponible.</h2>
          )}

          {Object.entries(accessories).length > 0 && (
            <AccessoriesList
              accessories={Object.values(accessories).filter((item) =>
                filterElement(item, ["name"], searchFilter)
              )}
            />
          )}

          <Box position="fixed" bottom={30} right={30}>
            <Fab
              color="primary"
              aria-label="add"
              component={Link}
              to={"/accessories/new"}
            >
              <AddIcon />
            </Fab>
          </Box>
        </div>
      </Route>
      <Route
        path={"/accessories/:accessoryId/show"}
        children={<AccessoriesShow />}
      />
      <Route path={"/accessories/new"} children={<AccessoriesNew />} />
      <Route
        path={"/accessories/:accessoryId/edit"}
        children={<AccessoriesEdit />}
      />
      <Route>
        <Redirect to={"/accessories"} />
      </Route>
    </Switch>
  );
}
