import { CALL_API } from "../middleware/api";
import {productColor} from "../helpers/schema";
import { shouldFetch } from "../helpers/api";
import React from "react";

// ----------------------
// Product Fetching
// ----------------------
export const FETCH_PRODUCT_COLORS_REQUEST = "FETCH_PRODUCT_COLORS_REQUEST";
export const FETCH_PRODUCT_COLORS_SUCCESS = "FETCH_PRODUCT_COLORS_SUCCESS";
export const FETCH_PRODUCT_COLORS_FAILURE = "FETCH_PRODUCT_COLORS_FAILURE";
export const INVALIDATE_PRODUCT_COLORS    = "INVALIDATE_PRODUCT_COLORS";

function invalidateProductColors () {
    return {type: INVALIDATE_PRODUCT_COLORS};
}

function fetchProductColorsIfNeeded () {
    return (dispatch, getState) => {
        if (shouldFetch(getState(), "productColors")) {
            return dispatch(fetchProductColors());
        }
    };
}

const fetchProductColors = () => ({
    [CALL_API]: {
        types: [
            FETCH_PRODUCT_COLORS_REQUEST,
            FETCH_PRODUCT_COLORS_SUCCESS,
            FETCH_PRODUCT_COLORS_FAILURE
        ],
        endpoint: `product_colors?pagination=false`,
        schema: [productColor],
    }
});

export const productColorsActions = {
    invalidateProductColors,
    fetchProductColorsIfNeeded,
};
