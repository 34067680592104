import { Grid, InputAdornment } from "@material-ui/core";
import { Checkboxes, TextField } from "mui-rff";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { accessoriesActions } from "../../../actions/accessories.actions";
import ImgUploader from "../../../components/imgUploader";
import PdfUploader from "../../../components/pdfUploader";

const endAdornment = <InputAdornment position="start">€</InputAdornment>;

const formFields = [
  {
    mdSize: 6,
    xsSize: 12,
    field: (
      <TextField label="Reference" name="reference" margin="none" required />
    ),
  },
  {
    mdSize: 6,
    xsSize: 12,
    field: <TextField label="Nom" name="name" margin="none" required />,
  },
  {
    mdSize: 12,
    xsSize: 12,
    field: (
      <Checkboxes
        name="autoCalc"
        data={[
          {
            label: "Calcul automatique",
          },
        ]}
      />
    ),
  },
];

export default function AccessoriesForm(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accessoriesActions.fetchAccessoriesIfNeeded());
  }, [dispatch]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={8} container spacing={2}>
        {formFields.map((item, idx) => (
          <Grid
            className={"mb-4"}
            item
            md={item.mdSize}
            xs={item.xsSize}
            key={idx}
          >
            {item.field}
          </Grid>
        ))}
      </Grid>

      <Grid
        item
        md={4}
        xs={12}
        container
        alignItems={"center"}
        justify={"center"}
      >
        <Grid item lg={8} md={10} xs={8}>
          <ImgUploader
            image={props.image}
            beforeText="Ajouter un icon"
            afterText="Modifier l'icon"
            onResult={(result) => props.setValue("image", result.id)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} container spacing={2}>
        <PdfUploader
          pdf={props.pdf}
          beforeText="Ajouter un pdf"
          afterText="Modifier le pdf"
          onResult={(result) => props.setValue("pdf", result.id)}
        />
      </Grid>
    </Grid>
  );
}

AccessoriesForm.propTypes = {
  setValue: PropTypes.func.isRequired,
};
