import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { Fragment, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import {
  EDIT_SHIPPING_ERROR,
  EDIT_SHIPPING_REQUEST,
  EDIT_SHIPPING_RESPONSE,
  shippingsActions,
} from "../../../actions/shippings.actions";
import HeaderTitle from "../../../components/HeaderTitle";
import ShippingsRow from "../../../components/shipping/ShippingsRow";
import { selectShippings } from "../../../helpers/schema";

const useStyles = makeStyles({
  container: {
    maxHeight: "78vh",
  },
});

export default function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadItems, _setLoadItems] = React.useState(50);

  const loadItemsRef = React.useRef(loadItems);
  const setLoadItems = (data) => {
    loadItemsRef.current = data;
    _setLoadItems(data);
  };

  const isFetching = useSelector((state) => {
    return state.shippings.isFetching;
  }, shallowEqual);

  const shippings = useSelector((state) => {
    return Object.values(selectShippings(state)) || [];
  }, shallowEqual);

  useEffect(() => {
    dispatch(shippingsActions.fetchShippingsIfNeeded());
    dispatch(appActions.setNextPage("/shipping"));
  }, [dispatch]);

  const handleRefreshClick = (event) => {
    event.preventDefault();
    dispatch(shippingsActions.invalidateShippings());
    dispatch(shippingsActions.fetchShippingsIfNeeded());
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      "& td": {
        color: "white",
        fontWeight: "bold",
      },
    },
  }))(TableRow);

  /* Group shippings by label */
  Array.prototype.groupBy = function (key) {
    return this.reduce(function (r, a, i) {
      if (!i || r[r.length - 1][0][key] !== a[key]) {
        return r.concat([[a]]);
      }
      r[r.length - 1].push(a);
      return r;
    }, []);
  };

  const shippingsByLabel = shippings?.groupBy("label") || [];
  const shippingsByZone = shippings.reduce(function (r, a) {
    r[a.shippingZone.id] = r[a.shippingZone.id] || [];
    r[a.shippingZone.id].push(a);
    return r;
  }, Object.create(null));

  return (
    <Switch>
      <Route exact path="/shipping">
        <HeaderTitle
          mb={3}
          title="Transport"
          right={
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
              disabled={isFetching}
            >
              Actualiser
            </Button>
          }
        />

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Désignation</TableCell>
                <TableCell align={"center"}>&lt; 100m²</TableCell>
                <TableCell align={"center"}>101 - 300m²</TableCell>
                <TableCell align={"center"}>301 - 600m²</TableCell>
                <TableCell align={"center"}>601 - 1000m²</TableCell>
                <TableCell align={"center"}>&gt; 1000m²</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(shippingsByZone) &&
                Object.values(shippingsByZone)?.length > 0 &&
                Object.values(shippingsByZone).map((shippingsItem, index) => (
                  <Fragment key={`shippingsItem${index}`}>
                    {shippingsItem &&
                      shippingsItem?.length > 0 &&
                      shippingsItem
                        .groupBy("label")
                        .map((shippingsRow, index) => (
                          <ShippingsRow
                            shippings={shippingsRow}
                            name={shippingsRow[0].label}
                            shippingZone={shippingsRow[0].shippingZone}
                            start={EDIT_SHIPPING_REQUEST}
                            resolve={EDIT_SHIPPING_RESPONSE}
                            reject={EDIT_SHIPPING_ERROR}
                            key={`shippingRow${index}`}
                          />
                        ))}
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Route>
    </Switch>
  );
}
