import {
  Box,
  Button,
  makeStyles,
  Step,
  StepConnector,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  getStepContent,
  getSteps,
  getProjectStep,
} from "../../helpers/project";
import { history } from "../../helpers/history";

import { selectProject } from "../../helpers/schema";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export const useStyles = makeStyles((theme) => ({
  p0: {
    padding: 0,
  },
  stepperHeader: {
    backgroundColor: theme.palette.background.primary,
  },
  stepperContainer: {
    backgroundColor: theme.palette.background.primary,
  },
  stepperNav: {
    color: theme.palette.stepperHeader.primary,
  },
  buttonIcon: {
    fontSize: "2rem",
  },
}));

export const useStylesStep = makeStyles((theme) => ({
  root: {
    color: theme.palette.stepperHeader.active,
    "&$active": {
      color: theme.palette.stepperHeader.active,
      "& $text": {
        fill: theme.palette.stepperHeader.primary,
      },
    },
    "&$completed": {
      color: theme.palette.stepperHeader.primary,
    },
  },
  active: {},
  completed: {},
}));

export const useStylesStepDisabled = makeStyles((theme) => ({
  root: {
    color: theme.palette.stepperHeader.disabled,
    "&$active": {
      color: theme.palette.stepperHeader.primary,
      "& $text": {
        fill: theme.palette.stepperHeader.primaryText,
      },
    },
    "&$completed": {
      color: theme.palette.stepperHeader.primary,
    },
  },
  active: {},
  completed: {},
}));

export const useStylesStepBtn = makeStyles((theme) => ({
  root: {
    cursor: "not-allowed",
    pointerEvents: "inherit",
  },
}));

const ColorlibConnector = withStyles({
  root: {
    "& $line": {
      borderColor: "rgba(0, 0, 0, 0.20)",
    },
  },
  line: {},
})(StepConnector);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.background.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: "-10px",
  },
}))(Tooltip);

export const StepperHeader = React.memo((props) => {
  const classes = useStyles();
  const classesStep = useStylesStep();
  const classesStepDisabled = useStylesStepDisabled();
  const classesStepBtn = useStylesStepBtn();
  const { activeStep, submit, shouldLink, disabled, projectId, last } = props;

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  const [hoveredStep, setHoveredStep] = useState(null);
  const steps = getSteps();

  return (
    <Box
      className={classes.stepperHeader}
      p={2}
      display="flex"
      flexDirection="column"
    >
      <Stepper
        connector={<ColorlibConnector />}
        className={classes.stepperContainer}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <LightTooltip
            key={index}
            title={
              <>
                <Typography variant="overline">
                  {getStepContent(index)}
                </Typography>
              </>
            }
            aria-label="add"
            open={hoveredStep === index}
          >
            <Step
              className={classes.p0}
              key={label}
              onMouseEnter={() => setHoveredStep(index)}
              onMouseLeave={() => setHoveredStep(null)}
              completed={
                hoveredStep !== null
                  ? hoveredStep === index
                    ? false
                    : getProjectStep(project) >= index + 1 &&
                      index !== activeStep
                  : getProjectStep(project) >= index + 1 && index !== activeStep
              }
              classes={classesStepBtn}
            >
              <StepButton
                disabled={index + 1 > getProjectStep(project)}
                onClick={() =>
                  history.push(`/projects/${projectId}/step${index + 1}`)
                }
              >
                <StepLabel
                  StepIconProps={{
                    classes:
                      index + 1 > getProjectStep(project)
                        ? classesStepDisabled
                        : classesStep,
                  }}
                ></StepLabel>
              </StepButton>
            </Step>
          </LightTooltip>
        ))}
      </Stepper>
      <Box
        mt={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          disabled={activeStep === 0 || disabled}
          className={classes.stepperNav}
          component={NavLink}
          to={`/projects/${projectId}/step${activeStep}`}
        >
          <ChevronLeft className={classes.buttonIcon} />
        </Button>
        <Typography className={classes.stepperNav} variant="h6">
          {getStepContent(activeStep)}
        </Typography>

        {!shouldLink && (
          <Button
            disabled={last || disabled}
            onClick={submit}
            className={classes.stepperNav}
          >
            <ChevronRight className={classes.buttonIcon} />
          </Button>
        )}

        {shouldLink && (
          <Button
            disabled={disabled || last}
            component={NavLink}
            to={`/projects/${projectId}/step${activeStep + 2}`}
            className={classes.stepperNav}
          >
            <ChevronRight className={classes.buttonIcon} />
          </Button>
        )}
      </Box>
    </Box>
  );
});

StepperHeader.propTypes = {
  activeStep: PropTypes.number.isRequired,
  shouldLink: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
};
