import { SET_CURRENT_PROJECT, SET_NEXT_PAGE } from "../actions/app.actions";

export function app(
    state = {
        nextPage: "/",
        currentProject: ""
    }, action) {
    switch (action.type) {
        case SET_NEXT_PAGE:
            return {
                ...state,
                nextPage: action.nextPage
            };
        case SET_CURRENT_PROJECT:
            return {
                ...state,
                currentProject: action.currentProject
            };
        default:
            return state
    }
}