import {
    INVALIDATE_PROJECTS,
    FETCH_PROJECTS_REQUEST,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECT_REQUEST,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_FAILURE,
    EDIT_PROJECT_REQUEST,
    EDIT_PROJECT_RESPONSE,
    EDIT_PROJECT_ERROR,
    GENERATE_PROJECT_QUOTATION_REQUEST,
    GENERATE_PROJECT_QUOTATION_RESPONSE,
    GENERATE_PROJECT_QUOTATION_FAILURE
} from '../actions/projects.actions'
import { ADD_ENTITIES } from "../actions/actions";
import { getProjectIdFromEndpoint } from "../helpers/api";
import { mergeEntitiesWithState, project } from "../helpers/schema";
import { EDIT_SLOPE_ERROR, EDIT_SLOPE_REQUEST, EDIT_SLOPE_RESPONSE } from "../actions/slopes.actions";

const schema = project;

export function projects(
    state = {
        isFetching: false,
        isUpdating: false,
        isUpdatingSlope: false,
        isGeneratingQuotation: false,
        didInvalidate: false,
        fetched: false,
        all: {},
        fetchFailed: []
    },
    action
) {
    switch (action.type) {
        case INVALIDATE_PROJECTS:
            return Object.assign({}, state, {
                ...state,
                didInvalidate: true
            })
        case ADD_ENTITIES:
            let data = action.payload.project;
            if (!data) return state

            return Object.assign({}, state, {
                all: mergeEntitiesWithState(data, state.all, schema)
            })
        case FETCH_PROJECTS_REQUEST:
            return Object.assign({}, state, {
                ...state,
                isFetching: true,
                didInvalidate: false
            })
        case FETCH_PROJECT_REQUEST:
            return Object.assign({}, state, {
                ...state,
                isFetching: true
            })
        case FETCH_PROJECTS_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                fetched: true,
                isFetching: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        case FETCH_PROJECT_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                isFetching: false
            })
        case FETCH_PROJECT_FAILURE:
            return Object.assign({}, state, {
                ...state,
                isFetching: false,
                fetchFailed: [...state.fetchFailed, getProjectIdFromEndpoint(action.endpoint)]
            })
        case EDIT_PROJECT_REQUEST:
            return Object.assign({}, state, {
                ...state,
                isUpdating: true
            })
        case EDIT_PROJECT_RESPONSE:
            return Object.assign({}, state, {
                ...state,
                isUpdating: false
            })
        case EDIT_PROJECT_ERROR:
            return Object.assign({}, state, {
                ...state,
                isUpdating: false
            })
        case EDIT_SLOPE_REQUEST:
            return Object.assign({}, state, {
                ...state,
                isUpdatingSlope: true
            })
        case EDIT_SLOPE_RESPONSE:
            return Object.assign({}, state, {
                ...state,
                isUpdatingSlope: false
            })
        case EDIT_SLOPE_ERROR:
            return Object.assign({}, state, {
                ...state,
                isUpdatingSlope: false
            })
        case GENERATE_PROJECT_QUOTATION_REQUEST:
            return Object.assign({}, state, {
                ...state,
                isGeneratingQuotation: true
            })
        case GENERATE_PROJECT_QUOTATION_RESPONSE:
            return Object.assign({}, state, {
                ...state,
                isGeneratingQuotation: false
            })
        case GENERATE_PROJECT_QUOTATION_FAILURE:
            return Object.assign({}, state, {
                ...state,
                isGeneratingQuotation: false
            })
        default:
            return state
    }
}
