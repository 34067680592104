import { media } from "../helpers/schema";
import { CALL_API } from "../middleware/api";

// ----------------------
// Media Add
// ----------------------

export const UPLOAD_CSV_REQUEST  = "UPLOAD_CSV_REQUEST";
export const UPLOAD_CSV_RESPONSE = "UPLOAD_CSV_RESPONSE";
export const UPLOAD_CSV_ERROR    = "UPLOAD_CSV_ERROR";
export const UPLOAD_ESTIMATIF_CSV_REQUEST  = "UPLOAD_ESTIMATIF_CSV_REQUEST";
export const UPLOAD_ESTIMATIF_CSV_RESPONSE = "UPLOAD_ESTIMATIF_CSV_RESPONSE";
export const UPLOAD_ESTIMATIF_CSV_ERROR    = "UPLOAD_ESTIMATIF_CSV_ERROR";
export const DOWNLOAD_CSV_REQUEST  = "DOWNLOAD_CSV_REQUEST";
export const DOWNLOAD_CSV_RESPONSE = "DOWNLOAD_CSV_RESPONSE";
export const DOWNLOAD_CSV_ERROR    = "DOWNLOAD_CSV_ERROR";

const upload_estimatif = (file) => (dispatch) => {
    let data = new FormData();
    data.append("file", file);
    dispatch({
        [CALL_API]: {
            types: [
                UPLOAD_ESTIMATIF_CSV_REQUEST,
                UPLOAD_ESTIMATIF_CSV_RESPONSE,
                UPLOAD_ESTIMATIF_CSV_ERROR
            ],
            endpoint: `admin/price_estimateds/load`,
            schema: media,
            requestOptions: {
                method: "POST",
                body: data
            }
        }
    });
};

const upload = (file) => (dispatch) => {
    let data = new FormData();
    data.append("file", file);
    dispatch({
        [CALL_API]: {
            types: [
                UPLOAD_CSV_REQUEST,
                UPLOAD_CSV_RESPONSE,
                UPLOAD_CSV_ERROR
            ],
            endpoint: `admin/prices/load`,
            schema: media,
            requestOptions: {
                method: "POST",
                body: data
            }
        }
    });
};

const download = (entity) => (dispatch) => {
    return dispatch({
        [CALL_API]: {
            types: [
                DOWNLOAD_CSV_REQUEST,
                DOWNLOAD_CSV_RESPONSE,
                DOWNLOAD_CSV_ERROR
            ],
            endpoint: `admin/${entity}/download`,
            schema: media,
            requestOptions: {
                method: "GET",
                headers: { Accept: "text/csv" },
            }
        }
    });
};

export const csvActions = {
    upload,
    upload_estimatif,
    download
};
