import {
    ROLE_ADMIN as ADMIN_LABEL,
    ROLE_MERCHANT as MERCHANT_LABEL,
    ROLE_ROOFER as ROOFER_LABEL,
    ROLE_SALESPERSON as SALESPERSON_LABEL
} from "./constante";

export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_SALESPERSON = "ROLE_SALESPERSON"
export const ROLE_ROOFER = "ROLE_ROOFER"
export const ROLE_MERCHANT = "ROLE_MERCHANT"

export function getUser() {
    return JSON.parse(localStorage.getItem('user'));
}

export const isAdmin = () => getUserRole() === ROLE_ADMIN

export function getUserRole() {
    let user = localStorage.getItem('user')

    if (user) {
        user = JSON.parse(user);

        if (user.decoded.roles.includes(ROLE_ADMIN)) return ROLE_ADMIN
        else if (user.decoded.roles.includes(ROLE_SALESPERSON)) return ROLE_SALESPERSON
        else if (user.decoded.roles.includes(ROLE_MERCHANT)) return ROLE_MERCHANT
        else if (user.decoded.roles.includes(ROLE_ROOFER)) return ROLE_ROOFER
    }

    return false;
}

export function getAvailableRoleForNewUser() {
    switch (getUserRole()) {
        case ROLE_ADMIN:
            return [
                {
                    label: ADMIN_LABEL,
                    value: ROLE_ADMIN,
                },
                {
                    label: SALESPERSON_LABEL,
                    value: ROLE_SALESPERSON,
                },
                {
                    label: MERCHANT_LABEL,
                    value: ROLE_MERCHANT,
                },
                {
                    label: ROOFER_LABEL,
                    value: ROLE_ROOFER,
                }
            ];
        case "ROLE_SALESPERSON":
            return [
                {
                    label: MERCHANT_LABEL,
                    value: ROLE_MERCHANT,
                },
                {
                    label: ROOFER_LABEL,
                    value: ROLE_ROOFER,
                }
            ];
        case "ROLE_MERCHANT":
            return [
                {
                    label: ROOFER_LABEL,
                    value: ROLE_ROOFER,
                }
            ];
        default:
            return []
    }
}
