import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Box,
  makeStyles,
} from "@material-ui/core";
import ValidationModal from "../ValidationModal";
import MenuActions from "../MenuActions";
import { useDispatch } from "react-redux";
import { insulationsActions } from "../../actions/insulations.actions";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ImageSearch from "@material-ui/icons/ImageSearch";

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function InsulationsList(props) {
  const classes = useStyles();
  const [selectedInsulation, setSelectedInsulation] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const dispatch = useDispatch();

  const handleCloseMenuList = (option, insulation) => {
    if (option === "Supprimer") {
      setSelectedInsulation(insulation);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleInsulationDelete = () => {
    setDeleting(true);

    dispatch(insulationsActions.disable(selectedInsulation.id)).then(() => {
      setDeleting(false);
      setOpenModal(false);
    });
  };

  return (
    <>
      <ValidationModal
        loading={deleting}
        onClose={handleCloseModal}
        onClick={handleInsulationDelete}
        open={openModal}
        title={"Êtes-vous sûr de vouloir supprimer cette isolation ?"}
        paragraph={"Sa suppression est définitive."}
      />

      <List>
        {props.insulations.map((insulation, i) => (
          <div key={i}>
            <ListItem button>
              <ListItemAvatar>
                {insulation.image && (
                  <Avatar
                    variant={"circle"}
                    src={
                      process.env.REACT_APP_API_URL +
                      insulation.image.contentUrl
                    }
                  ></Avatar>
                )}
                {!insulation.image && (
                  <Avatar>
                    <ImageSearch />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={<Typography>{insulation.name}</Typography>}
                secondary={
                  <Box display="flex">
                    <Grid className={classes.textSecondary} container>
                      <Grid item xs={4}>
                        <Typography variant={"body2"}>
                          {insulation.reference}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          label={insulation.thickness + "mm"}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <MenuActions
                  onClose={(option) => handleCloseMenuList(option, insulation)}
                  options={{
                    show: {
                      name: "Voir",
                      link: `/insulations/${insulation.id}/show`
                    },
                    edit: {
                      name: "Editer",
                      link: `/insulations/${insulation.id}/edit`
                    },
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        ))}
      </List>
    </>
  );
}
