import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { useParams } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import {
  EDIT_INSULATION_ERROR,
  EDIT_INSULATION_REQUEST,
  EDIT_INSULATION_RESPONSE,
} from "../../../actions/insulations.actions";
import { promiseListener } from "../../../helpers/store";
import InsulationsForm from "./InsulationsForm";

export default function InsulationsEdit() {
  let { insulationId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.setNextPage("/insulations"));
  }, [dispatch]);

  let insulation = useSelector((state) => {
    return state.insulations.all[insulationId] || {};
  });

  // let image = useSelector((state) => {
  //   return state.medias.all[insulation?.image?.id] || {};
  // });

  return (
    <div>
      <MakeAsyncFunction
        listener={promiseListener}
        start={EDIT_INSULATION_REQUEST}
        resolve={EDIT_INSULATION_RESPONSE}
        reject={EDIT_INSULATION_ERROR}
      >
        {(onSubmit) => (
          <Form
            onSubmit={(form) => {
              form.thickness = parseFloat(form.thickness);
              form.wave = form.wave ? parseFloat(form.wave) : 0;
              return onSubmit(form);
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={insulation}
            render={({ handleSubmit, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <InsulationsForm
                  image={insulation.image?.contentUrl || null}
                  setValue={form.mutators.setValue}
                />

                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Editer
                  </Button>
                </Box>
              </form>
            )}
          />
        )}
      </MakeAsyncFunction>
    </div>
  );
}
