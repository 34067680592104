import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom';

import { history } from '../helpers/history';
import { PrivateRoute} from '../components/PrivateRoute';
import Login from './Login'
import Frame from "./Frame";

import '../stylesheets/main.scss'
import PasswordReset from "./PasswordReset";
import PasswordResetRequest from "./PasswordResetRequest";

export default class Root extends Component {
    render() {
        return (
            <div>
                <Router history={history}>
                    <div>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/reset_password" component={PasswordResetRequest} />
                            <Route exact path="/reset_password/:token" component={PasswordReset} />
                            <PrivateRoute path="/" component={Frame} />
                        </Switch>
                    </div>
                </Router>
            </div>
        )
    }
}