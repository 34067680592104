import {
  Box,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import { Alert, AlertTitle } from "@material-ui/lab";
import arrayMutators from "final-form-arrays";
import { TextField } from "mui-rff";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import { Redirect, useParams } from "react-router-dom";
import { appActions } from "../../../actions/app.actions";
import { projectsActions } from "../../../actions/projects.actions";
import {
  EDIT_SLOPE_ERROR,
  EDIT_SLOPE_REQUEST,
  EDIT_SLOPE_RESPONSE,
} from "../../../actions/slopes.actions";
import { GridColumn } from "../../../components/projects/grid/GridColumn";
import StepperButtons from "../../../components/projects/StepperButtons";
import { StepperHeader } from "../../../components/projects/StepperHeader";
import {
  constraintsFormToSlopeApi,
  heightLeftForSlope,
  isCellAdjacentToSelection,
  isSelected,
  isSlopeFull,
} from "../../../helpers/grid";
import { getProjectStep, useStyles } from "../../../helpers/project";
import { selectProject } from "../../../helpers/schema";
import { promiseListener } from "../../../helpers/store";

export const useStylesStep6 = makeStyles((theme) => ({
  rowSize: {
    height: 40,
  },
  gridContainer: {
    overflowX: "auto",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
  },
}));

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      height: 40,
    },
  },
})(TextField);

export default function Step6() {
  let { projectId } = useParams();
  const classes = useStyles();
  const classesStep6 = useStylesStep6();
  const dispatch = useDispatch();
  const [activeStep] = React.useState(5);
  const [constraintsDef, setConstraintsDef] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const constraintId = useRef(0);
  const isMouseDown = useRef(false);
  const [editQueue, setEditQueue] = useState([]);
  const [selectedBySlope, _setSelectedBySlope] = useState({});
  const processQueueRef = useRef({});
  const [placementToggle, _setPlacementToggle] = useState({
    constraint: false,
    enlightment: false,
  });
  const [EnlightmentCount, setEnlightmentCount] = useState(0);
  let searchTimeout = 0;
  let finalForm = useRef([false]);

  const selectedBySlopeRef = React.useRef(selectedBySlope);
  const setSelectedBySlope = (data) => {
    selectedBySlopeRef.current = data;
    _setSelectedBySlope(data);
  };

  const placementToggleRef = React.useRef(placementToggle);
  const setPlacementToggle = (data) => {
    placementToggleRef.current = data;
    _setPlacementToggle(data);
  };

  const isFetchingProject = useSelector((state) => {
    return state.projects.isFetching;
  });

  const project = useSelector((state) => {
    return selectProject(state, projectId) || {};
  }, shallowEqual);

  const isUpdatingSlope = useSelector((state) => {
    return selectProject(state, projectId)?.isUpdatingSlope || false;
  }, shallowEqual);

  useEffect(() => {
    dispatch(projectsActions.fetchProjectIfNeeded(projectId));
    dispatch(appActions.setNextPage("/projects/{currentProject}/step7"));
    dispatch(appActions.setCurrentProject(projectId));
  }, [dispatch, projectId, isFetchingProject]);

  const insertRow = (form, slopeIndex, rowIndex, formValues) => {
    let linesSize = formValues.linesSize;
    let rest = (
      formValues.height - linesSize.reduce((a, b) => a + parseFloat(b), 0.0)
    ).toFixed(2);
    let result =
      linesSize[rowIndex - 1] < rest || rest <= 0
        ? linesSize[rowIndex - 1]
        : rest;

    Array(project.slopes[slopeIndex].columnNumber)
      .fill(null)
      .forEach((col, colIndex) => {
        let colValues = formValues.constraints[colIndex];
        if (
          colValues[rowIndex] &&
          colValues[rowIndex - 1] !== false &&
          colValues[rowIndex - 1].id === colValues[rowIndex].id
        ) {
          form.mutators.insertAt(
            `constraints[${colIndex}]`,
            rowIndex,
            colValues[rowIndex - 1]
          );
        } else {
          form.mutators.insertAt(`constraints[${colIndex}]`, rowIndex, false);
        }
      });

    form.mutators.insertAt(`linesSize`, rowIndex, result);
  };

  const removeRow = (form, slopeIndex, rowIndex) => {
    Array(project.slopes[slopeIndex].columnNumber)
      .fill(null)
      .forEach((col, colIndex) => {
        form.mutators.remove(`constraints[${colIndex}]`, rowIndex);
      });

    form.mutators.remove(`linesSize`, rowIndex);
  };

  const addCellToSelected = (y, x, slope, reset = false) => {
    if (!isSelected(y, x, selectedBySlopeRef.current[slope])) {
      let start = reset ? [] : selectedBySlopeRef.current[slope];
      setSelectedBySlope({
        ...selectedBySlopeRef.current,
        [slope]: [...start, { y, x }],
      });
    }
  };

  const selectionIsConstraint = useCallback((slope) => {
    let values = finalForm.current[slope].getState().values["constraints"];
    return selectedBySlopeRef.current[slope].some(
      (cell) => values[cell.x][cell.y] !== false
    );
  }, []);

  const selectCell = (y, x, slope, form) => {
    let shouldReset = false;
    let shouldAddConstraint = true;
    let values = form.getState().values["constraints"];

    // if the cell to select is already a constraint or light, we reset
    if (selectedBySlopeRef.current[slope].length !== 0 && values[x][y]) {
      shouldReset = true;
    }

    // if any of the selected cells are part of a constraint or light, we reset
    if (selectionIsConstraint(slope)) {
      shouldReset = true;
      shouldAddConstraint = false;
    }

    // if the cell to select is not adjacent to current selection, we reset
    if (
      selectedBySlopeRef.current[slope].length !== 0 &&
      !isCellAdjacentToSelection({ y, x }, selectedBySlopeRef.current[slope])
    ) {
      shouldReset = true;
    }

    if (
      !shouldReset &&
      editQueue.some(
        (item) =>
          item.slopeIndex === slope &&
          item.x === selectedBySlopeRef.current[slope][0].x &&
          item.values.some((el) =>
            selectedBySlopeRef.current[slope].some((sel) => sel.y === el.y)
          )
      )
    ) {
      shouldReset = true;
    }

    // If we should reset but a toggle is ON, we should do more
    if (shouldReset && shouldAddConstraint) {
      if (placementToggleRef.current.constraint) {
        addConstraintToQueue(slope, "constraint");
      } else if (placementToggleRef.current.enlightment) {
        addConstraintToQueue(slope, "enlightment");
        setEnlightmentCount(EnlightmentCount + 1);
      }
    }

    addCellToSelected(y, x, slope, shouldReset);
  };

  const resetSlopeSelection = useCallback((slope) => {
    setSelectedBySlope({
      ...selectedBySlopeRef.current,
      [slope]: [],
    });
  }, []);

  const addConstraint = useCallback(
    (slopeIndex, type) => {
      let selected = JSON.parse(
        JSON.stringify(selectedBySlopeRef.current[slopeIndex])
      );
      let id = ++constraintId.current;

      applyConstraintsToForm([
        {
          id,
          slopeIndex,
          x: selected[0].x,
          values: [...selected],
          type: type,
        },
      ]);

      resetSlopeSelection(slopeIndex);
    },
    [resetSlopeSelection]
  );

  const addConstraintToQueue = useCallback(
    (slopeIndex, type) => {
      let selected = JSON.parse(
        JSON.stringify(selectedBySlopeRef.current[slopeIndex])
      );
      let id = ++constraintId.current;

      setEditQueue((queue) => {
        if (
          queue.some(
            (item) =>
              item.slopeIndex === slopeIndex &&
              item.x === selected[0].x &&
              item.values.some((el) => selected.some((sel) => sel.y === el.y))
          )
        ) {
          return queue;
        } else {
          return [
            ...queue,
            {
              id,
              slopeIndex,
              x: selected[0].x,
              values: [...selected],
              type: type,
            },
          ];
        }
      });

      resetSlopeSelection(slopeIndex);
    },
    [resetSlopeSelection]
  );

  const applyConstraintsToForm = (constraints) => {
    let toUpdate = [];
    let constraintsForm = finalForm.current.map(
      (form) => form.getState().values.constraints
    );
    constraints.forEach((constraint) => {
      let y = constraint.values
        .map((value) => value.y)
        .reduce((a, b) => Math.min(a, b));

      constraint.values.forEach((cell) => {
        constraintsForm[constraint.slopeIndex][cell.x][cell.y] = {
          id: constraint.id,
          type: constraint.type,
          length: constraint.values.length,
          y,
          new: true,
        };

        if (!toUpdate.includes(constraint.slopeIndex)) {
          toUpdate.push(constraint.slopeIndex);
        }
      });
    });

    constraintsForm.forEach((constraint, index) => {
      finalForm.current[index].mutators.setValue("constraints", constraint);
    });

    // We submit changed form to commit all new constraints
    toUpdate.forEach((form) => finalForm.current[form].submit());
  };

  const addAllConstraint = useCallback(
    (type) => {
      Object.keys(selectedBySlopeRef.current).forEach((index) => {
        if (
          selectedBySlopeRef.current[index].length > 0 &&
          !selectionIsConstraint(index)
        ) {
          addConstraintToQueue(parseInt(index), type);
        }
      });
    },
    [addConstraintToQueue, selectionIsConstraint]
  );

  processQueueRef.current = () => {
    // Only process queue if mouse is not down (not dragging) & slope is not already updating & there is something to commit
    if (isMouseDown.current || isUpdatingSlope || editQueue.length === 0)
      return;

    applyConstraintsToForm(editQueue);

    setEditQueue([]);
  };

  useEffect(() => {
    const handleDocumentMouseUp = (event) => {
      if (event.button !== 2 && isMouseDown.current) {
        isMouseDown.current = !isMouseDown.current;
      }

      // when drag clic is done, we add selected to constraint if any toggle is set
      if (placementToggleRef.current.constraint) {
        addAllConstraint("constraint");
      } else if (placementToggleRef.current.enlightment) {
        addAllConstraint("enlightment");
        setEnlightmentCount(EnlightmentCount + 1);
      }

      processQueueRef.current();
    };

    const handleDocumentMouseDown = (event) => {
      if (event.button !== 2 && !isMouseDown.current) {
        isMouseDown.current = !isMouseDown.current;
      }
    };

    document.addEventListener("mousedown", handleDocumentMouseDown);
    document.addEventListener("mouseup", handleDocumentMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleDocumentMouseUp);
      document.addEventListener("mousedown", handleDocumentMouseDown);
    };
  }, [addAllConstraint]);

  const removeConstraint = (slopeIndex, form) => {
    let selected = selectedBySlopeRef.current[slopeIndex][0];
    let values = form.getState().values;
    let cell = values.constraints[selected.x][selected.y];
    let indexes = [];
    values.constraints[selected.x].forEach((item, index) => {
      if (item.type === "enlightment") {
        setEnlightmentCount(EnlightmentCount - 1);
      }
      if (item.id === cell.id) {
        indexes.push(index);
      }
    });
    indexes.forEach((index) => {
      form.mutators.setValue(`constraints[${selected.x}][${index}]`, false);
    });
    resetSlopeSelection(slopeIndex);
  };

  const removeAllConstraints = (slopeIndex, form) => {
    let values = form.getState().values;
    let indexes = [];

    values.constraints.forEach((column, columnIndex) => {
      values.constraints[columnIndex].forEach((item, index) => {
        indexes.push({
          x: columnIndex,
          y: index,
        });
      });
    });

    indexes.forEach((index) => {
      form.mutators.setValue(`constraints[${index.x}][${index.y}]`, false);
    });
    setEnlightmentCount(0);
    resetSlopeSelection(slopeIndex);
  };

  const addConstraintDefinition = (defs) => {
    let newDefs = { ...constraintsDef };

    defs.forEach((def) => {
      if (!newDefs[def.length]) {
        newDefs = {
          ...newDefs,
          [def.name]: {
            color: def.color,
            name: def.name,
          },
        };
      }
    });

    setConstraintsDef(newDefs);
  };

  const shouldRowUpdateConstraintDefinition = (columns, lineIndex) => {
    return (
      columns.filter((cell, index) => index === lineIndex && cell).length > 0
    );
  };

  let changed = false;
  if (
    project.slopes &&
    project.slopes.length > 0 &&
    initialValues.slopes &&
    initialValues.slopes.length > 0
  ) {
    project.slopes.forEach((slope, index) => {
      if (
        slope.constraintsUpdatedAt !==
        initialValues.slopes[index].constraintsUpdatedAt
      ) {
        changed = true;
      }
    });
  }

  if (
    !initialValues.slopes ||
    (initialValues.slopes.length === 0 && project.slopes) ||
    changed
  ) {
    let slopes = project.slopes ? Object.values(project.slopes) : [];
    let selected = {};
    let values = [];
    let sizes = [];

    if (getProjectStep(project) >= 5) {
      slopes.forEach((slope, index) => {
        slope = Object.assign({}, slope);

        if (slope.linesSize.length === 0) {
          slope.linesSize.push(1);
        }

        let constraints = [];
        Array(slope.columnNumber)
          .fill(null)
          .forEach(() => {
            constraints.push(Array(slope.linesSize.length).fill(false));
          });

        slope.constraints.forEach((constraint) => {
          if (constraint.type === "enlightment") {
            setEnlightmentCount(EnlightmentCount + 1);
          }
          constraints[constraint.x].splice(
            constraint.y,
            constraint.length,
            ...Array(constraint.length).fill(constraint)
          );

          sizes.push(constraint.fullLength);
        });

        slope.constraints = constraints;
        values.push(slope);

        if (selectedBySlopeRef.current[index] === undefined) {
          selected = {
            ...selected,
            [index]: [],
          };
        }
      });

      if (project.nomenclatures) {
        addConstraintDefinition(Object.values(project.nomenclatures));
      }

      if (Object.keys(selected).length > 0) {
        setSelectedBySlope(selected);
      }

      setInitialValues({
        slopes: values,
      });
    }
  }

  const handleTogglePlacementChange = (event) => {
    let newPlacementToggle = placementToggleRef.current;
    if (event.target.checked) {
      newPlacementToggle = {};
      Object.keys(placementToggleRef.current).forEach(
        (key) => (newPlacementToggle[key] = false)
      );
    }
    setPlacementToggle({
      ...newPlacementToggle,
      [event.target.name]: event.target.checked,
    });
  };

  if (!isFetchingProject && project.name && getProjectStep(project) < 5) {
    return (
      <Redirect to={`/projects/${project.id}/step${getProjectStep(project)}`} />
    );
  }

  return (
    <>
      {isFetchingProject && !project.name && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {!isFetchingProject && !project.name && (
        <Alert severity="error">Projet non trouvé !</Alert>
      )}

      {project.name && initialValues.slopes && (
        <div className={classes.root}>
          <StepperHeader
            activeStep={activeStep}
            projectId={parseInt(projectId)}
            submitting={false}
            shouldLink={true}
            disabled={initialValues.slopes.length === 0}
          />
          <Container maxWidth="lg">
            <Grid className={"mb-4"} item xs={12}>
              <Box mt={2} mb={2}>
                {initialValues.slopes.map((slope, slopeIndex) => (
                  <MakeAsyncFunction
                    key={slopeIndex}
                    listener={promiseListener}
                    start={EDIT_SLOPE_REQUEST}
                    resolve={EDIT_SLOPE_RESPONSE}
                    reject={EDIT_SLOPE_ERROR}
                  >
                    {(onSubmit) => (
                      <Form
                        initialValues={slope}
                        onSubmit={(form) => {
                          onSubmit(constraintsFormToSlopeApi(form));
                        }}
                        mutators={{
                          insertAt: (args, state, tools) => {
                            const [fieldsName, index, newStation] = args;
                            const values =
                              tools.getIn(
                                state,
                                `formState.values.${fieldsName}`
                              ) || [];

                            const newState = tools.setIn(
                              state,
                              `formState.values.${fieldsName}`,
                              [
                                ...values.slice(0, index),
                                newStation,
                                ...values.slice(index),
                              ]
                            );

                            Object.assign(state, newState);
                          },
                          setValue: (
                            [field, value],
                            state,
                            { changeValue }
                          ) => {
                            changeValue(state, field, () => value);
                          },
                          ...arrayMutators,
                        }}
                        render={({ form, handleSubmit, values }) => {
                          finalForm.current[slopeIndex] = form;
                          return (
                            <Fade key={slopeIndex} in={true}>
                              <ExpansionPanel defaultExpanded>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Grid item>
                                    <Typography className={classes.heading}>
                                      {values.name}
                                    </Typography>
                                  </Grid>
                                </ExpansionPanelSummary>

                                <Box m={2}>
                                  {isSlopeFull(values) && (
                                    <Alert severity="warning">
                                      <AlertTitle>Attention !</AlertTitle>
                                      Les dimensions des rangées dépassent les
                                      dimensions initialement définies pour le
                                      versant.
                                    </Alert>
                                  )}
                                </Box>
                                {project &&
                                  project?.illumination?.reference ===
                                    "NOILL" &&
                                  EnlightmentCount > 0 && (
                                    <Box m={2}>
                                      <Alert severity="warning">
                                        <strong>Rappel&nbsp;:</strong>
                                        &nbsp;Aucun éclairement prévu lors du
                                        devis estimatif
                                      </Alert>
                                    </Box>
                                  )}

                                {project &&
                                  project?.illumination?.reference !==
                                    "NOILL" &&
                                  EnlightmentCount === 0 && (
                                    <Box m={2}>
                                      <Alert severity="warning">
                                        <strong>Rappel&nbsp;:</strong>
                                        &nbsp;Un éclairement a été prévu lors du
                                        devis estimatif
                                      </Alert>
                                    </Box>
                                  )}

                                <Box display="flex" justifyContent="center">
                                  <Box
                                    mb={3}
                                    mt={1}
                                    p={2}
                                    display="flex"
                                    unselectable="on"
                                    className={classesStep6.gridContainer}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="column-reverse"
                                    >
                                      <FieldArray
                                        type="number"
                                        name={"linesSize"}
                                        inputProps={{
                                          step: "0.01",
                                          min: "0",
                                        }}
                                      >
                                        {({ fields }) =>
                                          fields.map((name, lineIndex) => (
                                            <Box key={lineIndex} display="flex">
                                              <IconButton
                                                style={{
                                                  position: "relative",
                                                  top: "-20px",
                                                }}
                                                size={"small"}
                                                color="primary"
                                                onClick={() => {
                                                  insertRow(
                                                    form,
                                                    slopeIndex,
                                                    lineIndex + 1,
                                                    values
                                                  );
                                                  if (searchTimeout)
                                                    clearTimeout(searchTimeout);
                                                  searchTimeout = setTimeout(
                                                    () => {
                                                      handleSubmit(values);
                                                    },
                                                    1000
                                                  );
                                                }}
                                                aria-label="add"
                                              >
                                                <AddIcon />
                                              </IconButton>
                                              <Box
                                                display="flex"
                                                justifyContent="center"
                                              >
                                                <Box
                                                  width="120px"
                                                  mr={1}
                                                  display="flex"
                                                >
                                                  <CustomTextField
                                                    size={"small"}
                                                    inputProps={{
                                                      min: 0,
                                                      style: {
                                                        textAlign: "center",
                                                      },
                                                    }}
                                                    variant={"outlined"}
                                                    name={name}
                                                  />

                                                  <Box
                                                    ml={1}
                                                    mr={1}
                                                    minWidth={20}
                                                    textAlign="center"
                                                    alignSelf="center"
                                                  >
                                                    <Typography>
                                                      {lineIndex + 1}
                                                    </Typography>
                                                  </Box>

                                                  <OnChange name={name}>
                                                    {(value, previous) => {
                                                      if (
                                                        shouldRowUpdateConstraintDefinition(
                                                          values.constraints,
                                                          lineIndex
                                                        )
                                                      ) {
                                                        values.linesSize =
                                                          values.linesSize.map(
                                                            (v) =>
                                                              v === undefined
                                                                ? "0"
                                                                : v
                                                          );
                                                        if (searchTimeout)
                                                          clearTimeout(
                                                            searchTimeout
                                                          );
                                                        searchTimeout =
                                                          setTimeout(() => {
                                                            handleSubmit(
                                                              values
                                                            );
                                                          }, 1000);
                                                      }
                                                    }}
                                                  </OnChange>
                                                </Box>
                                              </Box>
                                            </Box>
                                          ))
                                        }
                                      </FieldArray>
                                    </Box>

                                    {Array(values.constraints.length)
                                      .fill(null)
                                      .map((column, columnIndex) => (
                                        <Box
                                          key={columnIndex}
                                          display="flex"
                                          justifyContent="center"
                                          flexDirection="column"
                                          textAlign="center"
                                        >
                                          <Typography>
                                            {columnIndex + 1}
                                          </Typography>
                                          <GridColumn
                                            linesSize={values.linesSize}
                                            column={columnIndex}
                                            columnValues={
                                              values.constraints[columnIndex]
                                            }
                                            constraintsDef={constraintsDef}
                                            selected={
                                              selectedBySlopeRef.current[
                                                slopeIndex
                                              ] || []
                                            }
                                            queue={editQueue.filter(
                                              (item) =>
                                                item.slopeIndex ===
                                                  slopeIndex &&
                                                columnIndex === item.x
                                            )}
                                            onMouseDown={(y, x) => {
                                              selectCell(
                                                y,
                                                x,
                                                slopeIndex,
                                                form
                                              );
                                            }}
                                            onMouseEnter={(y, x) => {
                                              if (isMouseDown.current) {
                                                selectCell(
                                                  y,
                                                  x,
                                                  slopeIndex,
                                                  form
                                                );
                                              }
                                            }}
                                          />
                                        </Box>
                                      ))}
                                    <Box
                                      display="flex"
                                      flexDirection="column-reverse"
                                    >
                                      {Array(values.linesSize.length)
                                        .fill(null)
                                        .map((row, rowIndex) => (
                                          <Box
                                            key={rowIndex}
                                            display="flex"
                                            className={classesStep6.rowSize}
                                            justifyContent="center"
                                          >
                                            <IconButton
                                              size={"small"}
                                              color="primary"
                                              disabled={
                                                values.linesSize.length < 2
                                              }
                                              onClick={() => {
                                                removeRow(
                                                  form,
                                                  slopeIndex,
                                                  rowIndex
                                                );
                                                if (searchTimeout)
                                                  clearTimeout(searchTimeout);
                                                searchTimeout = setTimeout(
                                                  () => {
                                                    handleSubmit(values);
                                                  },
                                                  1000
                                                );
                                              }}
                                              aria-label="delete"
                                            >
                                              <RemoveIcon />
                                            </IconButton>
                                          </Box>
                                        ))}
                                    </Box>
                                  </Box>
                                </Box>

                                <Box display="flex">
                                  <Box flexGrow={1} flexBasis={0}>
                                    <Button
                                      color="primary"
                                      disabled={
                                        selectedBySlopeRef.current[slopeIndex]
                                          .length === 0 ||
                                        selectionIsConstraint(slopeIndex)
                                      }
                                      onClick={() => {
                                        addConstraint(slopeIndex, "constraint");
                                        handleSubmit(values);
                                      }}
                                    >
                                      contrainte
                                    </Button>

                                    <Button
                                      color="primary"
                                      disabled={
                                        selectedBySlopeRef.current[slopeIndex]
                                          .length === 0 ||
                                        selectionIsConstraint(slopeIndex)
                                      }
                                      onClick={() => {
                                        addConstraint(
                                          slopeIndex,
                                          "enlightment"
                                        );
                                        //handleSubmit(values);
                                      }}
                                    >
                                      Eclairement
                                    </Button>

                                    <Button
                                      color="primary"
                                      disabled={
                                        !selectionIsConstraint(slopeIndex)
                                      }
                                      onClick={() => {
                                        removeConstraint(slopeIndex, form);
                                        handleSubmit(values);
                                      }}
                                    >
                                      supprimer
                                    </Button>
                                  </Box>
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    flexGrow={1}
                                    flexBasis={0}
                                    alignItems="center"
                                  >
                                    <Typography>Placement rapide</Typography>
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      mx={3}
                                    >
                                      <Typography>Contrainte</Typography>
                                      <Switch
                                        checked={
                                          placementToggleRef.current.constraint
                                        }
                                        onChange={handleTogglePlacementChange}
                                        name="constraint"
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                    >
                                      <Typography>Eclairement</Typography>
                                      <Switch
                                        checked={
                                          placementToggleRef.current.enlightment
                                        }
                                        onChange={handleTogglePlacementChange}
                                        name="enlightment"
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                  <Box flexGrow={1} flexBasis={0} />
                                </Box>

                                <Divider />
                                <ExpansionPanelActions>
                                  <Box
                                    flexGrow={1}
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box display="flex">
                                      <Button
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                          removeAllConstraints(
                                            slopeIndex,
                                            form
                                          );
                                          handleSubmit(values);
                                        }}
                                      >
                                        Purger la grille
                                      </Button>
                                    </Box>
                                    <Box display="flex">
                                      <Box ml={1}>
                                        <Typography variant="overline">
                                          Hauteur : {values.height} ml
                                        </Typography>
                                      </Box>
                                      <Box ml={2}>
                                        <Typography
                                          variant="overline"
                                          color={
                                            isSlopeFull(values)
                                              ? "primary"
                                              : "secondary"
                                          }
                                        >
                                          Restant : {heightLeftForSlope(values)}{" "}
                                          ml
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Button
                                      size="small"
                                      color="primary"
                                      disabled={
                                        selectedBySlopeRef.current[slopeIndex]
                                          .length === 0
                                      }
                                      onClick={() =>
                                        resetSlopeSelection(slopeIndex)
                                      }
                                    >
                                      Réinitialiser la selection
                                    </Button>
                                  </Box>
                                </ExpansionPanelActions>
                              </ExpansionPanel>
                            </Fade>
                          );
                        }}
                      />
                    )}
                  </MakeAsyncFunction>
                ))}
              </Box>

              <StepperButtons
                activeStep={activeStep}
                projectId={parseInt(projectId)}
                submitting={false}
                shouldLink={true}
                disabled={initialValues.slopes.length === 0}
              />
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
}
