import { CALL_API } from "../middleware/api";
import { client } from "../helpers/schema";
import { shouldFetch } from "../helpers/api";
import { buildNextUrl, history } from "../helpers/history";
import {
  closeSnackbar as closeSnackbarAction,
  enqueueSnackbar as enqueueSnackbarAction,
} from "./notifications.actions";
import Button from "@material-ui/core/Button";
import React from "react";

// ----------------------
// Clients Fetching
// ----------------------
export const FETCH_CLIENTS_REQUEST = "FETCH_CLIENTS_REQUEST";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_FAILURE = "FETCH_CLIENTS_FAILURE";
export const INVALIDATE_CLIENTS = "INVALIDATE_CLIENTS";

function invalidateClients() {
  return { type: INVALIDATE_CLIENTS };
}

function fetchClientsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), "clients")) {
      return dispatch(fetchClients());
    }
  };
}

// sub actions
const fetchClients = () => ({
  [CALL_API]: {
    types: [
      FETCH_CLIENTS_REQUEST,
      FETCH_CLIENTS_SUCCESS,
      FETCH_CLIENTS_FAILURE,
    ],
    endpoint: `clients?pagination=false`,
    schema: [client],
  },
});

// ----------------------
// Client Add
// ----------------------

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_RESPONSE = "ADD_CLIENT_RESPONSE";
export const ADD_CLIENT_ERROR = "ADD_CLIENT_ERROR";

const add = (form) => (dispatch, getState) => dispatch({
  [CALL_API]: {
    types: [
      ADD_CLIENT_REQUEST,
      ADD_CLIENT_RESPONSE,
      ADD_CLIENT_ERROR
    ],
    endpoint: `clients`,
    schema: client,
    requestOptions: {
      headers: {"content-type": "application/json"},
      method: "POST",
      body: JSON.stringify(form)
    },
    success: result => {
      dispatch(
        enqueueSnackbarAction({
          message: "Client ajouté avec succès.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          },
        })
      );
    },
    failure: result => {
      dispatch(
        enqueueSnackbarAction({
          message: "Une erreur est survenue",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          },
        })
      );
    }
  }
});

// ----------------------
// Client Edit
// ----------------------

export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST";
export const EDIT_CLIENT_RESPONSE = "EDIT_CLIENT_RESPONSE";
export const EDIT_CLIENT_ERROR = "EDIT_CLIENT_ERROR";

const edit = (form) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [EDIT_CLIENT_REQUEST, EDIT_CLIENT_RESPONSE, EDIT_CLIENT_ERROR],
      endpoint: `clients/` + form.id,
      schema: client,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify(form),
      },
      success: (result) => {
        if (history.location.pathname.includes("clients")) {
          history.push(buildNextUrl(getState()));
        }
      },
    },
  });

// ----------------------
// Client disable
// ----------------------

export const DISABLE_CLIENT_REQUEST = "DISABLE_CLIENT_REQUEST";
export const DISABLE_CLIENT_RESPONSE = "DISABLE_CLIENT_RESPONSE";
export const DISABLE_CLIENT_ERROR = "DISABLE_CLIENT_ERROR";

const disable = (clientId) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      types: [
        DISABLE_CLIENT_REQUEST,
        DISABLE_CLIENT_RESPONSE,
        DISABLE_CLIENT_ERROR,
      ],
      endpoint: `clients/${clientId}`,
      schema: client,
      requestOptions: {
        method: "PUT", // 16/02 : had to use PUT instead of PATCH after updating API platform from 2.5.7 to 2.6.2
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ enabled: false }),
      },
      success: (result) => {
        dispatch(
          enqueueSnackbarAction({
            message: "Client supprimé avec succès.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              action: (key) => (
                <Button onClick={() => dispatch(closeSnackbarAction(key))}>
                  OK
                </Button>
              ),
            },
          })
        );
      },
    },
  });

export const clientsActions = {
  invalidateClients,
  fetchClientsIfNeeded,
  add,
  edit,
  disable,
};
