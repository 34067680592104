import {
  Box,
  Checkbox as MuiCheckbox,
  Grid,
  Typography,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { Autocomplete, Checkboxes, Radios, TextField } from "mui-rff";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import * as yup from "yup";
import { setLocale } from "yup";
import { DEPARTEMENTS } from "../../../helpers/constante";
import { validateFormValues } from "../../../helpers/form";
import { getAvailableRoleForNewUser, isAdmin } from "../../../helpers/user";

setLocale({
  mixed: {
    required: "champs requis",
  },
  string: {
    email: "Adresse email invalide",
  },
});

const validationSchema = yup.object({
  email: yup.string().email().required("Entrez une adresse email"),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.string().required(),
  address: yup.string().required(),
  postCode: yup.string().length(5, "le format est invalide").required(),
  city: yup.string().required(),
  sellMargin: yup
    .number()
    .min(0, "doit être supérieur à 0")
    .max(100, "doit être inférieur à 100"),
  password: yup.string(),
  passwordConfirmation: yup.string().when("password", {
    is: (password) => {
      return password !== undefined;
    },
    then: yup
      .string()
      .required()
      .oneOf([yup.ref("password")], "Le mot de passe doit correspondre"),
  }),
});

export const validate = validateFormValues(validationSchema);
export default function UsersForm(props) {
  let { values, setValue, account, create, edit } = props;

  const formFields = [
    {
      mdSize: 3,
      xsSize: 12,
      field: (
        <TextField
          label="Prénom"
          name="firstName"
          margin="none"
          required={true}
        />
      ),
    },
    {
      mdSize: 3,
      xsSize: 12,
      field: (
        <TextField label="Nom" name="lastName" margin="none" required={true} />
      ),
    },
    {
      mdSize: 4,
      xsSize: 12,
      field: (
        <TextField
          label="Email"
          type="email"
          name="email"
          margin="none"
          required={true}
          disabled={!isAdmin() && !create}
        />
      ),
    },
    {
      mdSize: 2,
      xsSize: 12,
      field: (
        <TextField
          label="N° de téléphone"
          type="tel"
          name="phone"
          margin="none"
          required={true}
        />
      ),
    },
    {
      mdSize: 4,
      xsSize: 12,
      field: (
        <TextField
          label="Adresse"
          name="address"
          margin="none"
          required={true}
        />
      ),
    },
    {
      mdSize: 2,
      xsSize: 12,
      field: (
        <TextField
          label="Code postal"
          name="postCode"
          margin="none"
          required={true}
        />
      ),
    },
    {
      mdSize: 3,
      xsSize: 12,
      field: (
        <TextField label="Ville" name="city" margin="none" required={true} />
      ),
    },
    {
      mdSize: 3,
      xsSize: 12,
      field: <TextField label="Société" name="company" margin="none" />,
    },
    {
      hide: create,
      mdSize: 6,
      xsSize: 12,
      field: (
        <TextField
          label="Mot de passe"
          type="password"
          name="password"
          autoComplete="off"
        />
      ),
    },
    {
      hide: create,
      mdSize: 6,
      xsSize: 12,
      field: (
        <TextField
          label="Mot de passe (confirmation)"
          type="password"
          name="passwordConfirmation"
          autoComplete="off"
        />
      ),
    },
    {
      hide: !isAdmin() && !create,
      mdSize: 6,
      xsSize: 12,
      field: (
        <Radios
          label="Rôle"
          name="roles"
          color={"primary"}
          required
          formControlProps={{ margin: "none" }}
          radioGroupProps={{ row: true }}
          data={getAvailableRoleForNewUser()}
        />
      ),
    },
    {
      hide: values.roles !== "ROLE_SALESPERSON",
      mdSize: 6,
      xsSize: 12,
      field: (
        <Autocomplete
          label="Départements en charge"
          name="cpInCharge"
          multiple
          options={DEPARTEMENTS}
          getOptionValue={(option) => option.code}
          getOptionLabel={(option) => option.nom}
          disableCloseOnSelect={true}
          disabled={!isAdmin() && !create}
          renderOption={(option, { selected }) => (
            <>
              <MuiCheckbox style={{ marginRight: 8 }} checked={selected} />
              {option.nom}
            </>
          )}
        />
      ),
    },
  ];

  return (
    <Grid container alignItems="flex-start" spacing={2}>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          {!item.hide && (
            <Grid className={"mb-4"} item md={item.mdSize} xs={item.xsSize}>
              {item.field}
            </Grid>
          )}
        </Fragment>
      ))}
      <Grid item xs={12} md={12} container spacing={2}>
        <Grid item xs={12} md={6} container justify={"center"}>
          <Box mb={3} display="flex" justifyContent="center">
            <Typography id="default-margin" variant="body1">
              Marge par défaut
            </Typography>
          </Box>
          <Slider
            aria-labelledby="default-margin"
            value={values.sellMargin || 0}
            onChange={(event, newValue) => setValue("sellMargin", newValue)}
          />
          <Grid item xs={6} md={2}>
            <TextField
              type="number"
              aria-labelledby="default-margin"
              name="sellMargin"
              inputProps={{
                step: "0.01",
                max: "100",
                min: "0",
              }}
            />
          </Grid>
        </Grid>

        {values.buyMargin !== undefined && (
          <Grid item xs={12} md={6} container justify={"center"}>
            <Box mb={3} display="flex" justifyContent="center">
              <Typography id="buy-margin" variant="body1">
                Marge de vente
              </Typography>
            </Box>
            <Slider
              aria-labelledby="buy-margin"
              value={values.buyMargin || 0}
              onChange={(event, newValue) => setValue("buyMargin", newValue)}
            />
            <Grid item xs={6} md={2}>
              <TextField
                type="number"
                aria-labelledby="buy-margin"
                name="buyMargin"
                inputProps={{
                  step: "0.01",
                  max: "100",
                  min: "0",
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {values.enabled !== undefined && (
        <Grid item xs={12} md={6} container>
          <Checkboxes
            name="enabled"
            data={[{ label: "Activé", value: "item1" }]}
          />
        </Grid>
      )}
    </Grid>
  );
}

UsersForm.propTypes = {
  values: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  account: PropTypes.bool,
  create: PropTypes.bool,
  edit: PropTypes.bool,
};
