import { Box, Button } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

export default function StepperButtons(props) {
  const { activeStep, shouldLink, disabled, projectId, last, submit } = props;

  return (
    <Box display="flex" justifyContent="space-between" mb={4}>
      <Button
        disabled={activeStep === 0}
        variant="contained"
        color="primary"
        component={NavLink}
        to={`/projects/${projectId}/step${activeStep}`}
      >
        Étape précédente
      </Button>

      {!shouldLink && (
        <Button
          variant="contained"
          onClick={submit}
          color="primary"
          disabled={disabled}
        >
          {last
            ? `Sauvegarder`
            : `Étape
            suivante`}
        </Button>
      )}

      {shouldLink && (
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          component={NavLink}
          to={`/projects/${projectId}/step${activeStep + 2}`}
        >
          {last
            ? `Sauvegarder`
            : `Étape
            suivante`}
        </Button>
      )}
    </Box>
  );
}
