export const SET_NEXT_PAGE       = "SET_NEXT_PAGE";
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";

function setNextPage (nextPage) {
    return {
        type: SET_NEXT_PAGE,
        nextPage
    };
}

function setCurrentProject (currentProject) {
    return {
        type: SET_CURRENT_PROJECT,
        currentProject
    };
}

export const appActions = {
    setNextPage,
    setCurrentProject,
};